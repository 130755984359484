<template>
  <div class="">
    <!-- Teacher switching tab -->
    <UIExit exit-url="students-period-attendance" label="Exit Period Attendance Detail" />
    <TitleWrapper
      :filter-option="false"
      title="ATT_DETAIL"
      tooltip-title="ATT_DETAIL"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <div>
        <div v-if="periodDetails">
          <DetailCard :details="periodData" class="mt-5" />
        </div>
        <TableWrapper v-if="periodAttendance.length">
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeads" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(attendance, index) in periodAttendance" :key="index">
              <TD>
                <span
                  class="cursor-pointer"
                  @click="redirectToStudentMode(attendance.student, 'students-period-attendance')"
                >
                  <SingleUserDisplay :user="attendance.student" label="full_name" :image="true" />
                </span>
              </TD>
              <TD>
                <UiStatus :title="attendance.status" :success="attendance.status === PRESENT" />
              </TD>
              <TD>
                {{ attendance.comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
    <Pagination
      v-if="periodDetails && periodDetails.total_records > 10"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(periodDetails.total_records, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { formatDate } from '@utils/moment.util'
import DATETIME_CONSTANT from '@src/constants/date-time-constants'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import UIExit from '@/src/components/UiElements/UIExit.vue'
import { redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    SingleUserDisplay,
    UIExit,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    DetailCard,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins],
  data() {
    return {
      periodData: {},
      PRESENT: 'present',
      GENERAL_CONSTANTS,
      DATETIME_CONSTANT,
      isLoading: false,
      filteredRecordLimit: 10,
      periodAttendance: {},
      periodDetails: null,
      tableHeads: ['Student Name', 'Status', 'Comment'],
    }
  },
  page: {
    title: 'Salary Detail',
  },
  mounted() {
    this.filterRecord()
    this.setRightBar()
  },
  methods: {
    redirectToStudentMode,
    formatDate,
    fullName,
    transformPeriodDetails() {
      return {
        period:
          this.periodDetails?.period_detail?.subject?.title || GENERAL_CONSTANTS.NOT_APPLICABLE,
        startTime: this.$filters.getTimeOnly(
          this.periodDetails?.period_detail?.started_at,
          DATETIME_CONSTANT.TIME12H,
        ),
        endTime: this.$filters.getTimeOnly(
          this.periodDetails?.period_detail?.ended_at,
          DATETIME_CONSTANT.TIME12H,
        ),
        teacher:
          this.periodDetails?.period_detail?.teacher?.first_name ||
          GENERAL_CONSTANTS.NOT_APPLICABLE,
        present: this.periodDetails?.present_count,
        absent: this.periodDetails?.absent_count,
      }
    },
    filterRecord(range) {
      this.isLoading = true
      let id = this.$route.params?.id
      let query = { skip: range?.skip || 0, limit: range?.limit || 10 }
      this.getPeriodAttendanceDetails({ query: query, id: id }).then((res) => {
        this.periodDetails = res?.data?.meta
        this.periodAttendance = res?.data?.records
        this.periodAttendance.forEach((attendance) => {
          fullName(attendance.student)
        })
        this.isLoading = false
        this.setRightBar()
        this.periodData = this.transformPeriodDetails()
      })
    },
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'ATT_DETAIL',
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('attendance', ['getPeriodAttendanceDetails']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style>
@media screen and (max-width: 424px) {
  .content-col {
    display: flex;
    flex-direction: column;
  }
  .xsm {
    display: flex;
    flex-direction: column;
  }
}
</style>
